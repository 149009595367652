import React from 'react';
import styled from 'styled-components';
import {
  Layout,
  LinkProps,
  HeadlineProps,
  ModuleContent,
  ImageProps,
} from '../../EditorContentTypes';
import CTAs from './CTAs';
import Headline from './Headline';

import Image from './Image';
import LegalData, { LegalDataProps } from './LegalData';
import Text from './Text';

export const BackgroundWrapper = styled.div`
  background: var(${(props) => props.theme.colors.ui.inverted});
  color: var(${(props) => props.theme.colors.ui.inverted});
`;

const StyledBlockContainer = styled.div`
  padding-block: var(${({ theme }) => theme.responsive.spacing.xxxl});
  box-sizing: border-box;

  display: flex;
  justify-content: left;
  flex-direction: column-reverse;

  &.img-left,
  &.img-right {
    @media (min-width: ${({ theme }) => theme.breakpoints.xs}px) {
      gap: var(${({ theme }) => theme.responsive.spacing.xl});
    }
  }

  & > * {
    flex: 0 1 50%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    margin-inline: 40px;

    &.img-left,
    &.img-right {
      gap: var(${({ theme }) => theme.responsive.spacing.xxl});
    }
    &.img-left {
      flex-direction: row-reverse;
    }

    &.img-right {
      flex-direction: row;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    margin-inline: 0;
    padding-inline: var(${({ theme }) => theme.responsive.spacing.xxxl});
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    margin-inline: var(${({ theme }) => theme.responsive.spacing.xxxl});
    &.article-width {
      max-width: 749px;
      margin-inline: auto;
      padding-inline: 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    max-width: 1440px;
    margin-inline: auto;
    &.article-width {
      max-width: 849px;
      padding-inline: 0;
    }
  }
`;

const TextContainer = styled.div`
  color: var(${({ theme }) => theme.colors.ui.inverted});
  margin-inline: var(${({ theme }) => theme.responsive.spacing.m});

  ${StyledBlockContainer}.img-top &.with-headline {
    margin-block-start: var(${({ theme }) => theme.responsive.spacing.xl});
  }

  ${StyledBlockContainer}.img-top &.without-headline {
    margin-block-start: var(${({ theme }) => theme.responsive.spacing.l});
  }

  &.text-only {
    margin-block-start: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.s}px) {
    margin-inline: var(${({ theme }) => theme.responsive.spacing.xl});
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    margin-inline: 0;
  }
`;

export interface BlockProps {
  readonly moduleContent: ModuleContent;
  readonly layout?: Layout;
  readonly headline?: HeadlineProps;
  readonly text?: string;
  readonly articleWidth?: boolean;
  readonly image: ImageProps;
  readonly links?: LinkProps[];
  readonly legalData?: LegalDataProps;
  readonly moduleIndex: number;
  readonly additionalModule: boolean;
}

const Block: React.FC<BlockProps> = React.memo(
  ({
    moduleContent,
    layout,
    headline,
    text,
    articleWidth,
    image,
    links,
    legalData,
    moduleIndex,
    additionalModule,
  }: BlockProps) => {
    const isTextOnly = moduleContent === `text-only`;
    const showImage = !isTextOnly;
    const isArticleWidth = articleWidth && isTextOnly;

    return (
      <StyledBlockContainer className={`${layout ?? ''} ${isArticleWidth ? 'article-width' : ''}`}>
        {text && (
          <TextContainer
            className={`${
              headline && headline?.text?.length > 0 ? 'with-headline' : 'without-headline'
            } ${moduleContent}`}
          >
            <>
              {headline && (
                <Headline
                  property={additionalModule ? 'headline_text' : 'topModule_headline_text'}
                  {...headline}
                />
              )}
              <Text property={additionalModule ? 'text' : 'topModule_text'} text={text} />

              {links && (
                <CTAs
                  property0Text={additionalModule ? 'links_0_text' : 'topModule_links_0_text'}
                  property1Text={additionalModule ? 'links_1_text' : 'topModule_links_1_text'}
                  links={links}
                  moduleIndex={moduleIndex}
                />
              )}
            </>
            {isTextOnly && legalData && (
              <LegalData
                propertyWLTP={
                  additionalModule ? 'legalData_wltpKeys' : 'topModule_legalData_wltpKeys'
                }
                propertyAdditionalText={
                  additionalModule
                    ? 'legalData_additionalText'
                    : 'topModule_legalData_additionalText'
                }
                isTextOnly={isTextOnly}
                {...legalData}
              />
            )}
          </TextContainer>
        )}

        {showImage && (
          <div>
            <Image
              propertyImageDesktop={
                additionalModule ? 'image_imageDesktop' : 'topModule_image_imageDesktop'
              }
              {...image}
            />
            {legalData && (
              <TextContainer>
                <LegalData
                  propertyWLTP={
                    additionalModule ? 'legalData_wltpKeys' : 'topModule_legalData_wltpKeys'
                  }
                  propertyAdditionalText={
                    additionalModule
                      ? 'legalData_additionalText'
                      : 'topModule_legalData_additionalText'
                  }
                  {...legalData}
                />
              </TextContainer>
            )}
          </div>
        )}
      </StyledBlockContainer>
    );
  },
);

export default Block;
