import { ContentFragment, ContentReference } from '@oneaudi/falcon-tools';

export type ModuleContent = 'text-and-image' | 'text-only' | 'image-only';

export type Layout = 'img-top' | 'img-left' | 'img-right';

export type HeadlineHierarchy = 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeadlineProps {
  readonly text: string;
  readonly hierarchy: HeadlineHierarchy;
  readonly property?: string;
}

export interface Asset {
  readonly assetValue?: string;
}

export interface ImageProps {
  readonly imageDesktop: Asset;
  readonly imageMobile: Asset;
  readonly alternativeText?: string;
  readonly propertyImageDesktop?: string;
}

export interface LegalData {
  readonly additionalText: string;
  readonly wltpKeys: string[];
}

export interface LinkProps {
  readonly href: string;
  readonly text: string;
}

export interface Module {
  readonly moduleContent: ModuleContent;
  readonly layout?: Layout;
  readonly headline: HeadlineProps;
  readonly text: string;
  readonly articleWidth?: boolean;
  readonly image: ImageProps;
  readonly legalData: LegalData;
  readonly links?: LinkProps[];
  readonly additionalModule?: boolean;
}

export interface Content {
  readonly topModule: Module;
  readonly additionalModules: Module[];
  readonly lightDarkMode: THEME.LIGHT | THEME.DARK;
}

export interface HeadlessContent extends Partial<ContentFragment> {
  __type: string;
  fields: {
    readonly topModule_moduleContent: 'text-and-image' | 'text-only';
    readonly topModule_layout: 'img-top' | 'img-left' | 'img-right';
    readonly topModule_headline_text: string;
    readonly topModule_headline_hierarchy: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    readonly topModule_text: string;
    readonly topModule_articleWidth: boolean;
    readonly topModule_image_imageMobile: ContentReference;
    readonly topModule_image_imageDesktop: ContentReference;
    readonly topModule_image_alternativeText: string;
    readonly topModule_legalData_wltpKeys: string[];
    readonly topModule_legalData_additionalText: string;
    readonly topModule_links_0_text: string;
    readonly topModule_links_0_href: string;
    readonly topModule_links_1_text: string;
    readonly topModule_links_1_href: string;
    readonly additionalModules: HeadlessModule[];
    readonly lightDarkMode: THEME;
  };
}

export interface HeadlessModule extends ContentFragment {
  fields: {
    readonly additionalModule?: boolean;
    readonly moduleContent: 'text-and-image' | 'text-only' | 'image-only';
    readonly layout: 'img-top' | 'img-left' | 'img-right';
    readonly headline_text: string;
    readonly headline_hierarchy: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    readonly text: string;
    readonly articleWidth: boolean;
    readonly image_imageMobile: ContentReference;
    readonly image_imageDesktop: ContentReference;
    readonly image_alternativeText: string;
    readonly legalData_wltpKeys: string[];
    readonly legalData_additionalText: string;
    readonly links_0_text: string;
    readonly links_0_href: string;
    readonly links_1_text: string;
    readonly links_1_href: string;
  };
}

export enum THEME {
  LIGHT = 'light',
  DARK = 'dark',
}
