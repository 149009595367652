import parse from 'html-react-parser';
import React, { ReactNode, cloneElement, FC } from 'react';
import styled from 'styled-components';
import { List, ListItem, ListProps } from '@audi/audi-ui-react';
import { FilterXSS } from 'xss';
import { renderReactNodeWithFootnotes } from '../../utils/renderReactNodeWithFootnotes';

const StyledList = styled(List as FC<ListProps>)`
  margin-block: var(${(props) => props.theme.responsive.spacing.m});
  margin-inline-start: var(${(props) => props.theme.responsive.spacing.m});
`;

const createElement = (type: string, props?: object, ...children: ReactNode[]): JSX.Element => {
  if (type === 'br') {
    return React.createElement(type, props);
  }
  const renderChildren = () => renderReactNodeWithFootnotes(children);
  if (type === 'ul') {
    return React.createElement(StyledList, props, renderChildren());
  }
  if (type === 'li') {
    return React.createElement(ListItem, props, renderChildren());
  }
  return React.createElement(type, props, renderChildren());
};

const sanitizer = new FilterXSS({
  whiteList: {
    b: [],
    i: [],
    u: [],
    ul: [],
    li: [],
    br: [],
    p: [],
    a: ['href'],
    strong: [],
  },
  stripIgnoreTagBody: ['script'],
  onIgnoreTag: () => '',
});

export const sanitizeRichText = (htmlString: string): string => sanitizer.process(htmlString);

export function convertRichText(htmlString: string) {
  const sanitizedHtmlString = sanitizeRichText(htmlString);

  return parse(sanitizedHtmlString, {
    library: {
      cloneElement,
      createElement,
      isValidElement: (element: unknown): boolean => {
        return Boolean(element);
      },
    },
  });
}
